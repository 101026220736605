.homePage {
  h1 {
    margin: 1em auto 0.75em;
  }
  .lm-Body {
    margin: 0 auto;
  }
  .vertical-rule {
    width: 1px;
    background-color: #E6E6E6;
    height: 100%;
    margin: auto;
  }
  
  .dna-logo {
    text-align: center;
  
    img {
      width: 66%;
    }
  }
}