#lmds-header {
  .header-logo {
    display: inline-block;
  }
  .navbar {
    width: 40%;
    display: inline-block;
    list-style: none;
    vertical-align: bottom;
    float: right;
    margin-top: 20px;
    li {
      display: inline-block;
      padding: 0 10px;
      a {
        color: #1A1446;
        text-decoration: none;
      }
    }
  }
}
